<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        :href="platformUrl()"
      >
        <platform-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ platformName() }}
        </h2>
        <small
          v-if="platformVersionName()"
          class="pl-50"
        >
          {{ platformVersionName() }}
        </small>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            v-if="!isOtpSent"
            fluid
            :src="imgUrl"
            alt="Register"
          />
          <otp-background v-else />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-button
            id="fakeSubmitButton"
            class="d-none"
          />
          <template v-if="!isOtpSent">
            <b-card-title class="mb-1">
              {{ $t('auth.register.title') }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('auth.register.subtitle', {platform: platformName()}) }}
            </b-card-text>
          </template>
          <template v-else>
            <b-card-title class="mb-1">
              {{ $t('auth.register.otp_subtitle') }}
            </b-card-title>
          </template>

          <!-- Registration Data Form -->
          <validation-observer
            v-if="!isOtpSent"
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              id="register-init-form"
              class="auth-register-form mt-2"
              @submit.prevent="requestOtp"
            >
              <!-- Merchant Name -->
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('inputs.first_name')"
                    label-for="merchant-firstname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('inputs.first_name')"
                      vid="first_name"
                      rules="required"
                    >
                      <b-form-input
                        id="merchant-firstname"
                        v-model="registrationData.first_name"
                        name="first_name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="John"
                        :disabled="isLoading"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('inputs.last_name')"
                    label-for="merchant-lastname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('inputs.last_name')"
                      vid="last_name"
                      rules="required"
                    >
                      <b-form-input
                        id="merchant-lastname"
                        v-model="registrationData.last_name"
                        name="last_name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Doe"
                        :disabled="isLoading"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- email -->
              <b-form-group
                :label="$t('inputs.email')"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('inputs.email')"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="registrationData.email"
                    name="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="myemail@example.com"
                    :disabled="isLoading"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                :label="$t('inputs.password')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('inputs.password')"
                  vid="password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="registrationData.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="password"
                      placeholder="············"
                      :disabled="isLoading"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password confirmation -->
              <b-form-group
                label-for="register-password-confirmed"
                :label="$t('inputs.password_confirmation')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('inputs.password_confirmation')"
                  vid="password_confirmation"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password-confirmed"
                      v-model="registrationData.password_confirmation"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="password_confirmation"
                      placeholder="············"
                      :disabled="isLoading"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- mobile -->
              <i-input
                v-model="registrationData.mobile"
                :label="$t('inputs.mobile')"
                :dial-code.sync="registrationData.mobile_code"
                :international-number.sync="registrationData.formatted_mobile"
                :country-code.sync="registrationData.country_code"
                type="tel"
                :disabled="isLoading"
                :input-container-bindings="{
                  'vid': 'mobile'
                }"
                :numeric-placeholder="true"
              />

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="registrationData.status"
                  name="checkbox-1"
                  :disabled="isLoading"
                >
                  {{ $t('inputs.privacy_agreement_1') }}
                  <b-link
                    :href="env('VUE_APP_LANDING_PAGE_URL') + '/pages/privacy'"
                    target="_blank"
                  >
                    {{ $t('inputs.privacy_agreement_2') }}
                  </b-link>
                  {{ $t('inputs.privacy_agreement_3') }}
                  <b-link
                    :href="env('VUE_APP_LANDING_PAGE_URL') + '/pages/terms-and-conditions'"
                    target="_blank"
                  >
                    {{ $t('inputs.privacy_agreement_4') }}
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                id="registerBtn"
                key="requestOtpBtn"
                variant="primary"
                block
                type="submit"
                :disabled="invalid || isLoading || (!!retryAfter)"
              >
                <template v-if="isLoading">
                  <b-spinner small />
                  <span class="sr-only">Loading...</span>
                </template>
                <template v-else>
                  {{ $t('auth.register.sign_up') }}
                  <span v-if="retryAfter"> ({{ retryAfter }})</span>
                </template>
              </b-button>
            </b-form>
          </validation-observer>

          <!-- OTP Form -->
          <validation-observer
            v-else
            ref="otpForm"
            #default="{invalid}"
          >
            <b-form
              id="register-finalize-form"
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <div>
                <b-alert
                  variant="primary"
                  show
                  class="mb-0"
                >
                  <div class="alert-body">
                    <span>{{ $t('auth.register.otp_sent_message') }} <strong class="ltr"><span
                      class="d-inline-block"
                    >{{ registrationData.formatted_mobile }}</span></strong>.</span>
                  </div>
                </b-alert>
                <small>{{ $t('auth.register.incorrect_mobile_message') }}
                  <b-link @click="backToRegistrationForm">{{ $t('auth.register.change_mobile') }}</b-link>
                </small>

              </div>
              <!-- OTP Code -->
              <b-form-group
                label=""
                label-for="register-otp-1"
                class="mt-3"
              >
                <h4>{{ $t('auth.register.enter_otp_code') }}</h4>
                <b-row class="ltr">
                  <b-col
                    v-for="i in 6"
                    :key="`register-otp-container-${i}`"
                    cols="2"
                    class="p-50"
                  >
                    <b-form-input
                      :id="`register-otp-${i}`"
                      v-model="otpCode[i-1]"
                      max="9"
                      maxlength="1"
                      :disabled="isLoading || !(i===1 || otpCode[i-2])"
                      :state="isOtpIncorrect ? false:null"
                      @keyup="onOtpEnter($event, i-1)"
                      @keydown="onOtpDelete($event, i-1)"
                    />

                    <validation-provider
                      :name="`OTP Code #${i}`"
                      :vid="`otp-${i}`"
                      rules="required"
                    >
                      <input
                        v-model="otpCode[i-1]"
                        type="hidden"
                      >
                    </validation-provider>
                  </b-col>

                </b-row>
                <small>{{ $t('auth.register.did_not_received_otp') }}
                  <b-link @click="resentOtp">{{ $t('auth.register.resent_otp') }}
                    <span v-if="retryAfter"> ({{ $t('auth.register.wait_seconds', {'seconds': retryAfter}) }})</span>
                  </b-link>
                </small>
              </b-form-group>

              <b-alert
                v-height-fade.appear
                variant="warning"
                :show="isFakeOtp"
                class="mb-1"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  {{ $t('auth.register.example_otp_warning') }}
                </div>
              </b-alert>

              <b-button
                ref="submitRegistrationBtn"
                variant="primary"
                block
                type="submit"
                :disabled="invalid || isLoading"
              >
                <template v-if="isLoading">
                  <b-spinner small />
                  <span class="sr-only">Loading...</span>
                </template>
                <template v-else>
                  {{ $t('auth.register.sign_up') }}
                </template>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('auth.register.already_have_an_account') }}</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{ $t('auth.register.sign_in_instead') }}</span>
            </b-link>
          </p>

          <!--          &lt;!&ndash; divider &ndash;&gt;-->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              or-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
          <!--            <b-button-->
          <!--              variant="facebook"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="FacebookIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="twitter"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="TwitterIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="google"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="MailIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="github"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="GithubIcon" />-->
          <!--            </b-button>-->
          <!--          </div>-->

          <language-switcher />
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PlatformLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BAlert, BSpinner,
} from 'bootstrap-vue'
import {
  required, email, confirmed,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import {
  catchException,
  env,
  getCookie,
  getLocale,
  isUnitTestMode,
  platformName,
  platformUrl,
  platformVersionName,
  toast,
  trackEvent,
} from '@core/utils/utils'
// Import the functions from the firebase SDKs
import { initializeApp } from 'firebase/app'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import {
  nextTick, onMounted, ref, watch,
} from 'vue'
import firebaseConfig from '@core/configs/firebase.json'
import OtpBackground from '@/views/pages/authentication/OtpBackground.vue'
import { heightFade } from '@core/directives/animations'
import useSession from '@/auth/jwt/useJwt'
import LanguageSwitcher from '@/views/pages/authentication/LanguageSwitcher.vue'
import FakeRecaptcha from '@core/utils/fake-recaptcha'
import router from '@/router'

let USE_CLIENT_SIDE_OTP_SERVICE = true

export default {
  components: {
    BSpinner,
    LanguageSwitcher,
    PlatformLogo,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    OtpBackground,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'height-fade': heightFade,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      otpIcon: require('@/assets/images/icons/otp.png'),
      // validation
      required,
      email,
      // weak_password,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    const refCode = this.$route?.query?.ref || null
    if (refCode) {
      this.registrationData.ref = refCode
    }

    ['_gclid', '_fbclid', '_fbp', '_fbc'].forEach(trackingCookieKey => {
      const trackingCookieValue = getCookie(trackingCookieKey)
      if (trackingCookieValue) {
        this.registrationData.tracking[trackingCookieKey.replace('_', '')] = trackingCookieValue
      }
    })

    trackEvent('InitiateRegistration', { refCode })
  },
  methods: {
    platformUrl,
    platformName,
    platformVersionName,
    env,
    onOtpEnter(event, index) {
      const value = this.otpCode[index]
      this.isOtpIncorrect = false
      if (value && index < 5) {
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'Backspace', 'Delete'].indexOf(event.key) !== -1) {
          return
        }
        event.target.parentElement.nextElementSibling.firstChild.focus()
      } else if (!value) {
        this.onOtpDelete(event, index)
      }
    },
    onOtpDelete(event, index) {
      if (index > 0 && event.key === 'ArrowLeft') {
        event.target.parentElement.previousElementSibling.firstChild.focus()
        return
      }
      if (event.key !== 'Backspace' && event.key !== 'Delete') {
        return
      }
      const value = this.otpCode[index]
      if (!value && index > 0) {
        event.target.parentElement.previousElementSibling.firstChild.focus()

        if (index < 5) {
          const emptySiblingsCascade = [0, 1, 2, 3, 4, 5].splice(index + 1, 6)
          emptySiblingsCascade.forEach(i => {
            this.otpCode[i] = null
          })
        }
      }
    },
    resentOtp() {
      if (this.retryAfter) {
        return
      }
      this.backToRegistrationForm()
      nextTick(() => {
        nextTick(() => {
          this.requestOtp()
        })
      })
    },
    localCatchException(error) {
      this.isLoading = false
      if (error?.response?.status === 422) {
        toast('warning', error.response.data.message, null, 'AlertTriangleIcon')
        this.$refs.registerForm.setErrors(error.response.data.errors)
      } else {
        catchException(error)
      }
    },
    requestOtp() {
      if (this.retryAfter) {
        return
      }
      if (this.isLoading) {
        return
      }
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          // if (!USE_CLIENT_SIDE_OTP_SERVICE) {
          //   this.isLoading = true
          //   window.recaptchaVerifier.verify()
          //   return
          // }

          this.isLoading = true
          useSession.validate(this.registrationData)
            .then(response => {
              USE_CLIENT_SIDE_OTP_SERVICE = response?.data?.data?.client || false
              this.isLoading = false
              window.recaptchaVerifier.verify()
                .then(recaptchaToken => {
                  this.registrationData.authentication_token = recaptchaToken
                  this.requestOtpCode()
                })
                .catch(error => {
                  this.localCatchException(error)
                })
                .finally(() => {
                  window.recaptchaVerifier.recaptcha.reset()
                })
            })
            .catch(error => {
              this.localCatchException(error)
            })
        }
      })
    },
    register() {
      if (this.isLoading) {
        return
      }
      this.$refs.otpForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.registrationData.mobile_otp = String(Object.values(this.otpCode).join(''))
          useSession.csrf().then(() => {
            useSession.register(this.registrationData)
              .then(response => {
                if (response.status === 201) {
                  const responseData = response.data
                  this.$router.replace({
                    name: 'auth-continue-registration',
                    params: { process: responseData.process_id, token: responseData.access_token },
                  })

                  trackEvent('CompleteRegistration', { process: responseData.process_id, email: this.registrationData.email, mobile: this.registrationData.formatted_mobile })
                } else {
                  catchException(new Error(`Unexpected response:${response.status}`))
                }
              })
              .catch(error => {
                this.isLoading = false
                if (error?.response?.status === 422) {
                  toast('warning', error.response.data.message, null, 'AlertTriangleIcon')
                  if (Object.hasOwn(error.response.data.errors, 'mobile_otp')) {
                    this.isOtpIncorrect = true
                    this.$refs.otpForm.setErrors(error.response.data.message)
                  } else {
                    // Back to the first form
                    this.isOtpSent = false
                    nextTick(() => {
                      this.$refs.registerForm.setErrors(error.response.data.errors)
                    })
                  }
                } else {
                  catchException(error)
                }
              })
          })
        }
      })
    },
    backToRegistrationForm() {
      this.isOtpIncorrect = false
      this.isOtpSent = false

      nextTick(() => {
        window.recaptchaVerifier.recaptcha.reset()
      })
    },
  },
  setup(props, ctx) {
    const isLoading = ref(false)
    const isOtpSent = ref(false)
    const isOtpIncorrect = ref(false)
    const isFakeOtp = ref(false)
    const retryAfter = ref(0)
    const registrationData = ref({
      // TODO: Remove default values
      first_name: '', // 'Islam',
      last_name: '', // 'Abdelfattah',
      email: '', // 'email@test.com',
      password: '', // 'A12345678',
      password_confirmation: '', // 'A12345678',
      mobile_code: '',
      mobile: '', // '+201234567890',
      formatted_mobile: '', // '+201234567890',
      country_code: null,
      status: true,
      mobile_otp: null,
      verification_id: null,
      authentication_token: null, // ReCaptcha token
      url: router.currentRoute.fullPath,
      tracking: {
        gclid: null,
        fbclid: null,
        fbp: null,
        fbc: null,
      },
    })
    const otpCode = ref({
      0: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
    })

    const messages = (getLocale() === 'en') ? {
      unknown: 'Failed to send OTP code, kindly review the entered mobile number.',
      too_many_requests: 'Too many requests! Please wait sometime then try again.',
      same_number_too_many_requests_title: 'Too many requests!',
      same_number_too_many_requests_description: 'Please try again later, or use another mobile number.',
      something_went_wrong: 'Something went wrong, please refersh the page and try again.',
      captcha_check_failed: 'Humanity check failed. Please refresh the page.',
      network_issue: 'Please check your internet connection.',
    } : {
      unknown: 'تعذر إرسال رمز التحقق إلى رقم هاتفك. برجاء مراجعة الرقم والمحاولة مرة أخرى.',
      too_many_requests: 'برجاء الانتظار قليلاً ثم إعادة المحاولة.',
      same_number_too_many_requests_title: 'محاولات متكررة',
      same_number_too_many_requests_description: 'برجاء المحاولة في وقت لاحق او إستخدام رقم آخر.',
      something_went_wrong: ' حدث خطأ ما! برجاء تحديث الصفحة ثم إعادة المحاولة.',
      captcha_check_failed: 'فشل إختبار التحقق البشري. برجاء تحديث الصفحة.',
      network_issue: 'برجاء التحقق من إتصالك بالإنترنت.',
    }

    const countDownWaitTime = () => {
      if (retryAfter.value <= 0) {
        return
      }
      // eslint-disable-next-line no-param-reassign
      retryAfter.value -= 1

      setTimeout(() => {
        countDownWaitTime(retryAfter)
      }, 1000)
    }
    const setCountDownTimer = t => {
      retryAfter.value = t
      countDownWaitTime()
    }

    // Initialize Firebase
    const app = initializeApp(firebaseConfig)

    const auth = getAuth(app)
    // auth.languageCode = 'it';
    auth.useDeviceLanguage()

    const requestOtpCodeServerSide = () => useSession.sendOtp(registrationData.value)
      .then(() => {
        isOtpSent.value = true
        isLoading.value = false
        retryAfter.value = 60
        countDownWaitTime()
      })
      .catch(error => {
        isLoading.value = false
        if (error?.response?.status === 422) {
          toast('warning', error.response.data.message, null, 'AlertTriangleIcon')
          // ctx.$refs.registerForm.setErrors(error.response.data.errors) // TODO:
        }
        if (error?.response?.status === 429) {
          toast('warning', messages.too_many_requests, null, 'AlertTriangleIcon')
          try {
            retryAfter.value = error.response.headers['retry-after'] || 0
            countDownWaitTime()
          } catch (e) {
            catchException(e)
          }
        } else {
          catchException(error)
          toast('warning', messages.unknown)
        }
      })
    const requestOtpCodeClientSide = () => {
      signInWithPhoneNumber(auth, registrationData.value.formatted_mobile, window.recaptchaVerifier)
        .then(confirmationResult => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult
          isOtpSent.value = true
          isLoading.value = false
          setCountDownTimer(60)
          registrationData.value.verification_id = confirmationResult.verificationId
          // ...
        }).catch(error => {
        // Error; SMS not sent
          catchException(error)
          switch (error?.code) {
            case 'auth/captcha-check-failed':
              toast('warning', messages.captcha_check_failed)
              break
            case 'auth/too-many-requests':
              requestOtpCodeServerSide().catch(() => {
                toast('warning', messages.same_number_too_many_requests_title, messages.same_number_too_many_requests_description)
                setCountDownTimer(60)
              })
              break
            case 'auth/network-request-failed':
              toast('warning', messages.network_issue, messages.network_issue)
              setCountDownTimer(60)
              break
            case 'auth/operation-not-allowed': // Not supported number
            default:
              // We failed to request OTP in client side, so we fallback to BE
              requestOtpCodeServerSide()
              // toast('warning', messages.unknown)
          }
          isLoading.value = false
        // ...
        })
    }
    const requestOtpCode = () => {
      if (isLoading.value) {
        return
      }
      if (isOtpSent.value) {
        isLoading.value = false
        return
      }
      isLoading.value = true

      trackEvent('requestOtpCode', { mobile: registrationData.value.formatted_mobile })
      if (USE_CLIENT_SIDE_OTP_SERVICE) {
        console.log('[1]')
        requestOtpCodeClientSide()
      } else {
        console.log('[2]')
        requestOtpCodeServerSide()
      }
    }

    const initRecaptcha = () => {
      if (isUnitTestMode()) {
        console.log('Unit Test Mode')
        window.recaptchaVerifier = new FakeRecaptcha()
        return
      }
      window.recaptchaVerifier = new RecaptchaVerifier(auth, document.getElementById('fakeSubmitButton'), {
        size: 'invisible',
        callback: () => {
          // console.log('RECAPTCHA CALLBACK')
        },
        'expired-callback': () => {
          // console.log('expired')
        },
        'error-callback': error => {
          toast('warning', messages.value.messages.something_went_wrong)
          console.log(error)
        },
      })
      window.recaptchaVerifier.render()
    }

    onMounted(() => {
      initRecaptcha()
      // isOtpSent.value = true
    })

    watch(
      otpCode,
      newValue => {
        isFakeOtp.value = Object.values(newValue).join('') === '088123'
        isOtpIncorrect.value = false
      },
      { deep: true },
    )
    return {
      isLoading,
      isOtpSent,
      isOtpIncorrect,
      isFakeOtp,
      registrationData,
      otpCode,
      retryAfter,
      initRecaptcha,
      requestOtpCode,
    }
  },

}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

[dir] #register-finalize-form input {
  text-align: center;
  padding: unset !important;
}

[dir] #register-finalize-form .was-validated .form-control:invalid, [dir] .form-control.is-invalid {
  border-color: #ea5455;
  background: unset !important;
  padding: 0.438rem 1rem !important;
}
</style>
